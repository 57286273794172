import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, BackendApiService } from './../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
// import { TermsAndConditionsComponent } from './../components/terms-and-conditions/terms-and-conditions.component';
import { ResetPasswordDialogComponent } from './../components/reset-password-dialog/reset-password-dialog.component';
// import { environment } from '../../environments/environment';
// import { isSubscription } from 'rxjs/internal/Subscription';
@Component({
  selector: 'app-account',
  standalone: true,
  providers: [AccountService],
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './account.component.html',
})
export class AccountComponent {
  formType: string = 'LOGIN';
  signUpForm: FormGroup;
  signInForm: FormGroup;
  userInfo: any = null;
  loader: boolean = false;
  registerQueryparams: any = null;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private backendApi: BackendApiService
  ) {
    this.signUpForm = this.fb.group(
      {
        orgname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );

    this.signInForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['orgId'] && params['environment']) {
        console.log(' Register Query Params');
        localStorage.clear();
        this.registerQueryparams = params;
        this.formType = 'SIGNUP';
        this.signUpForm.patchValue({
          email: this.registerQueryparams.email,
          orgname: this.registerQueryparams.email.split('@')[1].split('.')[0],
        });
        if (this.registerQueryparams.email) {
          this.checkIfEmailExist();
        }
      }
    });
  }
  ifEmailExist: boolean = false;
  checkIfEmailExist() {
    this.backendApi
      .getMethod(`isExist?email=${this.registerQueryparams.email}`)
      .subscribe({
        next: (response) => {
          if (response['email']) {
            console.log(response);
            this.toastr.success(
              'Email already exist, Please use your existing password with this email',
              'Success'
            );
            this.ifEmailExist = true;
            this.signUpForm.get('confirmPassword').disable();
            this.signUpForm.get('confirmPassword').updateValueAndValidity();
          }
        },
        error: (error) => {
          this.toastr.error(error.error.message, 'Error');
        },
      });
  }
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (password !== confirmPassword) {
      formGroup.get('confirmPassword').setErrors({ mismatch: true });
    } else {
      formGroup.get('confirmPassword').setErrors(null);
    }
  }

  onSubmit() {
    if (this.signUpForm.valid) {
      // Process the form data
      this.loader = true;
      console.log(this.signUpForm.value);
      let payload = {
        email: this.signUpForm.value.email,
        password: this.signUpForm.value.password,
        organization: this.signUpForm.value.orgname,
      };
      payload = { ...payload, ...this.registerQueryparams };

      this.accountService.register(payload).subscribe({
        next: (response: any) => {
          this.loader = false;
          if (response.isInstalled) {
            this.toastr.success(
              'Your salesforce account is all set!',
              'Success'
            );
            localStorage.clear();
            setTimeout(() => {
              this.signInForm.patchValue(this.signUpForm.value);
              this.login();
            }, 2000);
          } else if (response.isSubscribed) {
            this.router.navigate(['/onboard'], {
              queryParams: {
                accountId: response.accountId,
                userId: response.userId,
                environment: this.registerQueryparams.environment,
                isSubscription: true,
              },
            });
          } else {
            this.router.navigate(['/onboard'], {
              queryParams: {
                accountId: response.accountId,
                userId: response.userId,
                environment: this.registerQueryparams.environment,
              },
            });
          }
        },
        error: (error) => {
          this.loader = false;
          this.toastr.error(error.error.message, 'Error');
        },
      });
    } else {
      // Display error messages
      this.toastr.error('Form is Invalid', 'Error');
    }
  }

  login() {
    if (this.signInForm.valid) {
      // Process the form data
      this.loader = true;
      console.log(this.signUpForm.value);
      let payload = {
        email: this.signInForm.value.email,
        password: this.signInForm.value.password,
      };

      this.accountService.login(payload).subscribe({
        next: (response: any) => {
          if (response.challengeName) {
            this.dialog.open(ResetPasswordDialogComponent, {
              data: { email: this.signInForm.value.email, resetInfo: response },
              width: '400px',
              disableClose: true,
            });
          } else {
            this.loader = false;
            this.router.navigate(['/dashboard']);
          }
        },
        error: (error) => {
          this.loader = false;
          this.toastr.error(error.error.message, 'Error');
        },
      });
    } else {
      // Display error messages
      this.toastr.error('Form is Invalid', 'Error');
    }
  }

  changeFormType(type: string) {
    this.formType = type;
  }

  // openTermsAndConditions(): void {
  //   const dialogRef = this.dialog.open(TermsAndConditionsComponent, {
  //     width: '600px',
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       console.log('Terms and Conditions accepted');
  //     } else {
  //       console.log('Terms and Conditions declined');
  //     }
  //   });
  // }
}
