import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { inject } from '@angular/core';
import { AccountService } from './../../app/_services';
import { Router } from '@angular/router';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  // Pass the cloned request with the updated header to the next handler
  const router = inject(Router);
  const toastr = inject(ToastrService);
  const authService = inject(AccountService);
  return next(req).pipe(
    catchError((err) => {
      switch (err.status) {
        case 401:
          authService.logout();
          router.navigate(['/']);
          break;
        case 403:
          authService.logout();
          router.navigate(['/']);
          break;
        case 500:
          toastr.error(err.error.message, 'Error');
          break;
      }
      toastr.error(err.error.message, 'Error');
      return throwError(() => new Error('Unauthorized Exception'));
    })
  );
};
