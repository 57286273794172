import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './billing.component.html',
})
export class BillingComponent implements OnInit {
  bills = [];
  plans: any = [];
  loader: boolean = false;
  registerInfo: any = {};
  adminList: any = {};
  filterForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.filterForm = this.fb.group({
      environment: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.backendApi.getMethod(`billing`).subscribe({
      next: (resp: any) => {
        this.bills = resp.invoices;
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }
}
