import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PlanDetailsComponent } from './../../components/plan-details/plan-details.component';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  loader: boolean = false;
  registerInfo: any = {};
  dashboardInfo: any = {};
  filterForm: FormGroup;
  tokenInfo: any = {};
  planInfo: any = {};
  dateRangeForm: FormGroup;
  showFilter: boolean = false;
  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.registerInfo);
    this.filterForm = this.fb.group({
      environment: ['', Validators.required],
    });
    this.dateRangeForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.backendApi.getMethod(`dashboard`).subscribe({
      next: (resp: any) => {
        resp.dashboard.usagebyEnvAndOrgId =
          resp.dashboard.usagebyEnvAndOrgId.map((item) => {
            console.log(item);
            const environment =
              item.environment.charAt(0).toUpperCase() +
              item.environment.slice(1);
            const tokenlimit = parseInt(item.tokenlimit);
            const tokenUsed = parseInt(item.tokenUsed);
            const percentage = (tokenUsed / tokenlimit) * 100;
            return {
              environment: environment,
              orgId: item.orgId,
              name: item.name,
              tokenlimit: item.tokenlimit,
              tokenUsed: item.tokenUsed,
              percentage: percentage.toFixed(2) + '%',
            };
          });
        this.dashboardInfo = resp;
        this.filterForm.patchValue({
          environment: this.dashboardInfo.filter[0].orgId,
        });
        this.onChange();
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  validateDates() {
    const startDate = this.dateRangeForm.get('startDate').value;
    const endDate = this.dateRangeForm.get('endDate').value;
    if (startDate && endDate && endDate < startDate) {
      this.dateRangeForm.get('endDate').setErrors({ invalidEndDate: true });
    } else {
      this.dateRangeForm.get('endDate').setErrors(null);
    }
  }

  onEndDateChange() {
    this.validateDates();
    if (this.dateRangeForm.valid) {
      const startDate = this.dateRangeForm.get('startDate').value;
      const endDate = this.dateRangeForm.get('endDate').value;
      this.fetchTokenUsage(startDate, endDate);
    }
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    // if (!this.showFilter) {
    //   this.backendApi.getMethod(`getTokenUsageByDate`).subscribe({
    //     next: (resp: any) => {
    //       this.dashboardInfo['dashboard']['usageByEmail'] = resp.usageByEmail;
    //       console.log(resp);
    //     },
    //     error: (error: any) => {
    //       console.log(error);
    //     },
    //   });
    // }
  }

  fetchTokenUsage(startDate: string, endDate: string) {
    this.backendApi
      .getMethod(
        `getTokenUsageByDate?startDate=${startDate}&endDate=${endDate}`
      )
      .subscribe({
        next: (resp: any) => {
          this.dashboardInfo['dashboard']['usageByEmail'] = resp.usageByEmail;
          console.log(resp);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  openPlanDetails(): void {
    this.dialog.open(PlanDetailsComponent, {
      data: { plan: this.planInfo },
    });
  }

  userName(email): string {
    return email.split('@')[0].toUpperCase();
  }

  avatarLetter(email): string {
    return email.charAt(0).toUpperCase();
  }
  onChange() {
    this.backendApi
      .getMethod(
        `account-subscription?orgId=${this.filterForm.value.environment}`
      )
      .subscribe({
        next: (resp: any) => {
          console.log(resp);
          this.planInfo = resp.plan.filter((obj) => obj.isCurrentPlan)[0];
          this.tokenInfo = this.dashboardInfo.dashboard.tokenUsedbyOrg;
          console.log(this.planInfo, this.tokenInfo);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }
}
