import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<null>;
  public user: Observable<null>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('user')!) || null
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(payload: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-key': `${environment.xAPIKey}`,
    });
    return this.http
      .post(`${environment.apiUrl}signin`, payload, { headers: headers })
      .pipe(
        map((user: any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('userInfo', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  register(payload: any) {
    // Define headers with x-key-api
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-key': `${environment.xAPIKey}`,
    });

    return this.http
      .post(`${environment.apiUrl}signup`, payload, { headers: headers })
      .pipe(
        map((user: any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  getMethod(endpoint: string): Observable<[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-key': `${environment.xAPIKey}`,
    });
    return this.http.get<any>(`${environment.apiUrl}${endpoint}`, {
      headers: headers,
    });
  }

  patchMethod(endpoint) {
    return this.http.patch<any>(`${environment.apiUrl}${endpoint}`, {});
  }

  putMethod(endpoint: string, body: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}${endpoint}`, body);
  }

  logout() {
    console.log('Remove LOCAL STORAGE');
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
  }
}
