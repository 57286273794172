import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
// import { FormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-admin-dashboard',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: `./admin-dashboard.component.html`,
})
export class AdminDashboardComponent {
  loader: boolean = false;
  registerInfo: any = {};
  dashboardInfo: any = {};
  filterForm: FormGroup;
  tokenInfo: any = {};
  planInfo: any = {};

  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.registerInfo);
    this.filterForm = this.fb.group({
      environment: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.backendApi.getAdminMethod(`admin/accounts`).subscribe({
      next: (resp: any) => {
        resp.dashboard.usagebyEnvAndOrgId =
          resp.dashboard.usagebyEnvAndOrgId.map((item) => {
            const environment =
              item.environment.charAt(0).toUpperCase() +
              item.environment.slice(1);
            const tokenlimit = parseInt(item.tokenlimit);
            const tokenUsed = parseInt(item.tokenUsed);
            const percentage = (tokenUsed / tokenlimit) * 100;
            return {
              environment: environment,
              orgId: item.orgId,
              tokenlimit: item.tokenlimit,
              tokenUsed: item.tokenUsed,
              percentage: percentage.toFixed(2) + '%',
            };
          });
        this.dashboardInfo = resp;
        this.filterForm.patchValue({
          environment: this.dashboardInfo.filter[0].orgId,
        });
        this.onChange();
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  onChange() {
    this.backendApi
      .getMethod(
        `account-subscription?orgId=${this.filterForm.value.environment}`
      )
      .subscribe({
        next: (resp: any) => {
          console.log(resp);
          this.planInfo = resp.plan.filter((obj) => obj.isCurrentPlan)[0];
          this.tokenInfo =
            this.dashboardInfo.dashboard.usagebyEnvAndOrgId.filter(
              (obj) => obj.orgId == this.filterForm.value.environment
            )[0];
          console.log(this.planInfo, this.tokenInfo);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }
}
