<div class="pricing-table-2 py-6 md:py-12">
  <div class="flex justify-center" *ngIf="screenType !== 'API_KEY_SCREEN'">
    <div class="mx-4 p-4 w-[50%]">
      <div class="flex items-center">
        <div class="flex items-center text-blue relative">
          <div
            [ngClass]="{ 'bg-white !text-blue-600': screenType == 'PLAN' }"
            class="rounded-full transition duration-500 ease-in-out text-center text-white h-10 w-10 py-2 border-2 bg-blue-800 bg-blue-800"
          >
            1
          </div>
          <div
            class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-blue-600"
          >
            Pricing Plan
          </div>
        </div>
        <div
          class="flex-auto border-t-2 transition duration-500 ease-in-out bg-blue-800"
        ></div>
        <div class="flex items-center relative">
          <div
            [ngClass]="{
              'bg-white !text-blue-600': screenType == 'ESTABLISH_CONNECTION'
            }"
            class="rounded-full transition duration-500 ease-in-out text-center text-white h-10 w-10 py-2 border-2 bg-blue-800 bg-blue-800"
          >
            2
          </div>
          <div
            class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-blue-600"
          >
            Establish Connection
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto px-4" *ngIf="screenType == 'PLAN'">
    <div class="max-w-3xl mx-auto text-center mt-4" *ngIf="!reviewPlan">
      <h1 class="text-3xl md:text-4xl font-medium mb-4 md:mb-6 mt-4">
        Pricing Plans
      </h1>
      <p class="text-gray-500 xl:mx-12 mt-5">
        You have 3 plans to choose from for using FWR blocks. Basic blocks are
        FREE forever. Other premium blocks are also free. You can use it for
        your personal or commercial projects.
      </p>
    </div>

    <div
      class="w-full lg:flex lg:flex-row justify-center"
      *ngIf="plans.length; else plan_loader"
    >
      <div
        [ngClass]="{ 'border-yellow-400': plan.isRecommended }"
        class="mx-6 my-4 md:my-6 drop-shadow-lg w-[300px]"
        *ngFor="let plan of plans"
      >
        <div
          [ngClass]="{
            'border-green-500': reviewPlan,
            'border-indigo-600  hover:border-indigo-600': !reviewPlan,
            'border-yellow-400': plan.isRecommended
          }"
          class="pricing-plan border-t-4 border-solid border-white bg-white text-center max-w-sm mx-auto transition-colors duration-300"
        >
          <div class="p-2 md:py-4">
            <h4 class="font-medium leading-tight text-2xl mb-2">
              {{ plan.name }}
            </h4>
            <p class="text-gray-600">{{ plan.shortDesc }}</p>
          </div>
          <div
            class="pricing-amount bg-indigo-100 p-6 transition-colors duration-300"
          >
            <div class="">
              <span class="text-4xl font-semibold">{{ plan.price }}</span>
            </div>
          </div>
          <div class="p-6">
            <ul class="leading-loose">
              <li *ngFor="let feat of plan.features">{{ feat }}</li>
            </ul>
            <div class="mt-6 py-4" *ngIf="!reviewPlan">
              <button
                [disabled]="loader"
                [ngClass]="{ 'bg-blue-100': loader }"
                (click)="selectPlan(plan)"
                class="bg-blue-500 text-xl text-white py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-300"
              >
                <span> Get Started</span>
              </button>
            </div>
            <div class="mt-6 py-4 text-center" *ngIf="reviewPlan">
              <p class="text-green-600 text-lg my-4">
                You are enjoying the {{ plan.name }} plan!
              </p>
              <button
                (click)="this.screenType = 'ESTABLISH_CONNECTION'"
                class="bg-green-500 text-lg md:text-xl text-white py-2 px-6 w-full rounded"
              >
                Continue
              </button>
            </div>
          </div>
          <div
            *ngIf="plan.isRecommended"
            class="absolute top-0 right-0 bg-yellow-400 text-white text-sm font-bold px-2 py-1 rounded-bl-lg"
          >
            Recommended
          </div>
        </div>
      </div>
    </div>
    <ng-template #plan_loader>
      <div class="w-full mt-6 flex flex-row justify-center">
        Plans Loading...
      </div>
    </ng-template>
  </div>

  <div
    class="container mx-auto px-4"
    *ngIf="screenType == 'ESTABLISH_CONNECTION'"
  >
    <div class="max-w-3xl mx-auto text-center mt-4">
      <h1 class="text-3xl md:text-4xl font-medium mb-4 md:mb-6">
        Establish Connection
      </h1>
      <p class="text-gray-500 xl:mx-12">Connect Salesforce App</p>
      <div class="flex flex-row justify-center mt-4">
        <img src="./../../assets/sf-icon.png" alt="" class="w-[130px]" />
      </div>

      <div class="flex flex-row mt-4 justify-evenly">
        <button
          (click)="goBackTOCheckYourPlan()"
          class="text-xl text-blue-700 py-2 px-6 rounded hover:bg-blue-700 hover:text-white border border-blue-700 transition-colors duration-300 ml-3"
        >
          Preview Plan
        </button>

        <button
          (click)="createConnection()"
          class="bg-blue-700 text-xl text-white w-[350px] py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-300"
        >
          New Connection
        </button>
      </div>
    </div>
  </div>

  <div
    *ngIf="screenType == 'API_KEY_SCREEN'"
    class="flex flex-row justify-center mt-[10%]"
  >
    <div class="max-w-3xl mx-auto text-center mt-4">
      <h1 class="text-3xl md:text-4xl font-medium mb-4 md:mb-6">
        Connection Established
      </h1>
      <div class="flex flex-row justify-center mt-4">
        <img
          src="./../../assets/images/success-icon.png"
          alt=""
          class="w-[130px]"
        />
      </div>
      <div class="mt-2 text-lg font-thin">
        You are all good to start using Smart Compose app!
      </div>
      <div class="flex items-center justify-center">
        <div class="text-center">
          <div class="text-xl font-medium mb-4">
            Redirecting to Dashboard in
          </div>
          <div class="text-6xl font-thin text-blue-600">{{ countdown }}</div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="screenType == 'LOADER'"
    class="flex flex-row justify-center mt-[10%]"
  >
    Please wait....
  </div>
</div>
