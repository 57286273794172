<nav class="container ml-6 mt-2">
  <ol
    class="list-reset text-indigo-800 py-4 pl-4 rounded flex bg-grey-light text-grey text-sm font-semibold"
  >
    <li class="px-2">
      <a href="#" class="no-underline">Home</a>
    </li>
    <li>/</li>
    <li class="px-2">
      <a href="#" class="no-underline">Dashboard</a>
    </li>
  </ol>
</nav>

<div class="container mx-auto px-4 py-8">
  <div class="flex flex-row">
    <!-- Card 1 -->
    <div class="bg-white flex-1 shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img
            src="./../../../assets/images/token.png"
            alt=""
            class="w-[60px]"
          />
        </div>
        <div>
          <p class="text-gray-600 text-xl font-thin">Usages</p>
          <p class="text-4xl font-semibold text-gray-800 mt-2">
            {{ tokenInfo.usedToken }}/{{ tokenInfo.tokenLimit }}
          </p>
        </div>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="bg-white flex-1 shadow-lg rounded-lg p-6 ml-20">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img
            src="./../../../assets/images/plan.jpg"
            alt=""
            class="w-[100px]"
          />
        </div>
        <div>
          <p class="text-gray-600 text-xl font-thin">Monthly Plan</p>
          <p class="text-xl font-semibold text-gray-800">{{ planInfo.name }}</p>
          <div class="flex flex-row justify-between">
            <div
              (click)="navigateTo('/plans')"
              class="mt-2 text-xs font-semibold text-blue-800 cursor-pointer border border-blue-800 p-1 rounded text-center hover:bg-blue-800 hover:text-white"
            >
              Change Plan
            </div>
            <div
              (click)="openPlanDetails()"
              class="mt-2 ml-4 text-xs font-semibold text-blue-800 cursor-pointer border border-blue-800 p-1 rounded text-center hover:bg-blue-800 hover:text-white"
            >
              View Details
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto px-4 py-8">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="w-full">
          <div class="flex flex-row justify-between">
            <p class="text-gray-600 text-xl font-thin">
              Top Token Consumption by Users
            </p>
            <button
              (click)="toggleFilter()"
              class="border border-blue-600 text-blue-600 px-4 py-1 rounded text-sm"
            >
              <i class="fa fa-filter"></i> Filter
            </button>
          </div>

          <div class="flex flex-row justify-end">
            <div class="p-4" *ngIf="showFilter">
              <form [formGroup]="dateRangeForm">
                <div class="flex flex-row">
                  <div class="text-sm font-bold text-center mt-6 w-[120px]">
                    Filter By
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label for="startDate" class="block mb-2 text-sm"
                      >Start Date</label
                    >
                    <input
                      type="date"
                      id="startDate"
                      formControlName="startDate"
                      class="border rounded px-2 w-[90%]"
                      (change)="validateDates()"
                    />
                    <div
                      *ngIf="
                        dateRangeForm.get('startDate').touched &&
                        dateRangeForm.get('startDate').invalid
                      "
                      class="text-red-500"
                    >
                      <small
                        *ngIf="
                          dateRangeForm.get('startDate').hasError('required')
                        "
                        >Start Date is required</small
                      >
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label for="endDate" class="block mb-2 text-sm"
                      >End Date</label
                    >
                    <input
                      type="date"
                      id="endDate"
                      formControlName="endDate"
                      class="border rounded px-2 w-[90%]"
                      (change)="onEndDateChange()"
                    />
                    <div
                      *ngIf="
                        dateRangeForm.get('endDate').touched &&
                        dateRangeForm.get('endDate').invalid
                      "
                      class="text-red-500"
                    >
                      <small
                        *ngIf="
                          dateRangeForm.get('endDate').hasError('required')
                        "
                        >End Date is required</small
                      >
                      <small
                        *ngIf="
                          dateRangeForm
                            .get('endDate')
                            .hasError('invalidEndDate')
                        "
                        >End Date cannot be before Start Date</small
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="w-full mt-4">
            <!-- Check if dashboardInfo.dashboard?.usageByEmail has data -->
            <div
              *ngIf="
                dashboardInfo.dashboard?.usageByEmail?.length > 0;
                else noData
              "
            >
              <div
                *ngFor="let user of dashboardInfo.dashboard?.usageByEmail"
                class="flex items-center space-x-4 rounded px-4 w-[90%] py-1 shadow-md mt-1"
              >
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full"
                  >
                    {{ avatarLetter(user.email) }}
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <p class="text-sm font-medium text-gray-900 truncate">
                    {{ user.email }}
                  </p>
                  <p class="text-xs text-gray-500 truncate dark:text-gray-400">
                    {{ user.environment }}
                  </p>
                </div>
                <div
                  class="inline-flex items-center text-base font-semibold text-gray-900"
                >
                  <img
                    src="./../../../assets/images/token.png"
                    alt=""
                    class="w-[30px]"
                  />
                  <span class="absolute ml-[10px]">{{ user.tokenUsed }}</span>
                </div>
              </div>
            </div>

            <!-- Template for no data -->
            <ng-template #noData>
              <div class="text-center text-gray-500 py-4">No result found</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- Card 3 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="w-full">
          <p class="text-gray-600 text-xl font-thin">Usage By Environment</p>
          <div *ngFor="let env of dashboardInfo.dashboard?.usagebyEnvAndOrgId">
            <span class="text-xs"
              >{{ env.name }}
              <span class="bg-gray">({{ env.environment }})</span></span
            >
            <div
              class="bg-white rounded-xl shadow-sm overflow-hidden p-1 border"
            >
              <div class="relative h-3 flex items-center justify-center">
                <div
                  [ngClass]="{ 'w-[100%]': env.percentage }"
                  class="absolute top-0 bottom-0 left-0 rounded-lg bg-blue-200"
                ></div>
                <div class="relative text-blue-900 font-medium text-xs">
                  {{ env.percentage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
