<nav class="container ml-6 mt-2">
  <ol
    class="list-reset text-indigo-800 py-4 pl-4 rounded flex bg-grey-light text-grey text-sm font-semibold"
  >
    <li class="px-2">
      <a href="#" class="no-underline">Home</a>
    </li>
    <li>/</li>
    <li class="px-2">
      <a href="#" class="no-underline">User</a>
    </li>
  </ol>
</nav>
<div class="px-10 mx-auto mt-4">
  <div class="flex flex-col">
    <div class="flex flex-row justify-end">
      <button
        (click)="openPopUp()"
        class="bg-blue-500 text-sm text-white py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-300"
      >
        Invite User
      </button>
    </div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let admin of adminList">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {{ admin.email }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {{ userName(admin.username) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span
                    class="p-2 rounded-md"
                    [ngClass]="{
                      'bg-green-200 text-green-800': admin.status == 'enabled',
                      'bg-red-200 text-yellow-800': admin.status == 'Invited',
                      'bg-red-200 text-orange-800': admin.status == 'disabled'
                    }"
                  >
                    {{ admin.status }}</span
                  >
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button
                    *ngIf="admin.status !== 'Invited'"
                    (click)="enableDisableUser(admin)"
                    class="btn btn-secondary bg-green-600 hover:bg-green-400 text-white py-2 px-4 rounded"
                  >
                    {{ admin.status == "enabled" ? "Disable" : "Enable" }} User
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- invite-user-popup.component.html -->
<!-- invite-user-popup.component.html -->
<div
  class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
  *ngIf="isPopupVisible"
>
  <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
    <button
      class="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
      (click)="closePopup()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
    <h2 class="text-2xl font-bold mb-4">Invite User</h2>
    <form [formGroup]="inviteForm" (ngSubmit)="inviteUser()">
      <div class="mb-4">
        <label for="username" class="block text-sm font-medium text-gray-700"
          >Username</label
        >
        <input
          type="text"
          id="username"
          formControlName="username"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
        <div
          *ngIf="
            inviteForm.get('username')?.invalid &&
            (inviteForm.get('username')?.dirty ||
              inviteForm.get('username')?.touched)
          "
          class="text-red-500 text-sm mt-2"
        >
          Please enter a valid username.
        </div>
      </div>
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700"
          >Email</label
        >
        <input
          type="email"
          id="email"
          formControlName="email"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
        <div
          *ngIf="
            inviteForm.get('email')?.invalid &&
            (inviteForm.get('email')?.dirty || inviteForm.get('email')?.touched)
          "
          class="text-red-500 text-sm mt-2"
        >
          Please enter a valid email.
        </div>
      </div>
      <div class="flex justify-end space-x-4">
        <button
          type="button"
          (click)="closePopup()"
          class="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          [disabled]="inviteForm.invalid"
          class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Invite
        </button>
      </div>
    </form>
  </div>
</div>
