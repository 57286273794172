<nav class="container ml-6 mt-2">
  <ol
    class="list-reset text-indigo-800 py-4 pl-4 rounded flex bg-grey-light text-grey text-sm font-semibold"
  >
    <li class="px-2">
      <a href="#" class="no-underline">Home</a>
    </li>
    <li>/</li>
    <li class="px-2">
      <a href="#" class="no-underline">Dashboard</a>
    </li>
  </ol>
</nav>

<div class="container mx-auto px-4 py-8">
  <div class="flex flex-col w-[400px] mb-12" [formGroup]="filterForm">
    <span class="text-sm">Select Environment</span>
    <select
      formControlName="environment"
      (change)="onChange()"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border sm:text-sm rounded-md shadow-lg"
    >
      <option value="">Choose Environments</option>
      <option
        *ngFor="let filter of this.dashboardInfo.filter"
        [value]="filter.orgId"
      >
        {{ filter.environment }}
      </option>
    </select>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
    <!-- Card 1 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img
            src="./../../../assets/images/token.png"
            alt=""
            class="w-[60px]"
          />
          <!-- <i class="fas fa-user-alt text-4xl text-blue-500"></i> -->
        </div>
        <div>
          <p class="text-gray-600 text-xl font-thin">Current Monthly Usages</p>
          <p class="text-4xl font-semibold text-gray-800 mt-2">
            {{ tokenInfo.tokenUsed }}/{{ tokenInfo.tokenlimit }}
          </p>
        </div>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img
            src="./../../../assets/images/plan.jpg"
            alt=""
            class="w-[100px]"
          />
          <!-- <i class="fas fa-calendar-alt text-4xl text-green-500"></i> -->
        </div>
        <div>
          <p class="text-gray-600 text-xl font-thin">Plan</p>
          <p class="text-xl font-semibold text-gray-800">{{ planInfo.name }}</p>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-xs font-semibold text-blue-800 cursor-pointer border border-blue-800 p-1 rounded text-center hover:bg-blue-800 hover:text-white"
            >
              Change Plan
            </p>
            <p
              class="mt-2 ml-4 text-xs font-semibold text-blue-800 cursor-pointer border border-blue-800 p-1 rounded text-center hover:bg-blue-800 hover:text-white"
            >
              View Details
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Card 3 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="flex-shrink-0">
          <img
            src="./../../../assets/images/usages.png"
            alt=""
            class="w-[70px]"
          />
          <!-- <i class="fas fa-envelope text-4xl text-yellow-500"></i> -->
        </div>
        <div class="w-full">
          <p class="text-gray-600 text-xl font-thin">Usage By Feature</p>
          <p class="text-xl font-semibold text-gray-800">648</p>
          <!-- Info Bar -->
          <span class="text-xs">Email Generation</span>
          <div class="bg-white rounded-xl shadow-sm overflow-hidden p-1 border">
            <div class="relative h-3 flex items-center justify-center">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-lg w-[60%] bg-blue-200"
              ></div>
              <div class="relative text-blue-900 font-medium text-xs">60%</div>
            </div>
          </div>
          <span class="text-xs">Sentimental Analysis</span>

          <!-- Success Bar -->
          <div class="bg-white rounded-xl shadow-sm overflow-hidden p-1 border">
            <div class="relative h-3 flex items-center justify-center">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-lg w-[75%] bg-red-200"
              ></div>
              <div class="relative text-green-900 font-medium text-xs">75%</div>
            </div>
          </div>
          <!-- END Success Bar -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto px-4 py-8">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
    <!-- Card 1 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="w-full">
          <p class="text-gray-600 text-xl font-thin">
            Top 5 Consumption by Users
          </p>
          <div class="w-full mt-2">
            <div
              *ngFor="let user of ['Atul', 'Arun', 'Spencer', 'Abhideep']"
              class="flex items-center space-x-4 border rounded px-4 w-[80%] py-1 shadow-md mt-1"
            >
              <div class="flex-shrink-0">
                <img
                  class="w-8 h-8 rounded-full"
                  src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"
                  alt="Neil image"
                />
              </div>
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900 truncate">
                  {{ user }}
                </p>
                <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                  Number of Tokens
                </p>
              </div>
              <div
                class="inline-flex items-center text-base font-semibold text-gray-900"
              >
                <img
                  src="./../../../assets/images/token.png"
                  alt=""
                  class="w-[30px]"
                />
                320
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card 3 -->
    <div class="bg-white shadow-lg rounded-lg p-6">
      <div class="flex items-center space-x-4">
        <div class="w-full">
          <p class="text-gray-600 text-xl font-thin">Usage By Environment</p>
          <div *ngFor="let env of dashboardInfo.dashboard.usagebyEnvAndOrgId">
            <span class="text-xs">{{ env.environment }}</span>
            <div
              class="bg-white rounded-xl shadow-sm overflow-hidden p-1 border"
            >
              <div class="relative h-3 flex items-center justify-center">
                <div
                  [ngClass]="{ 'w-[75%]': env.percentage }"
                  class="absolute top-0 bottom-0 left-0 rounded-lg bg-blue-200"
                ></div>
                <div class="relative text-blue-900 font-medium text-xs">
                  {{ env.percentage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
