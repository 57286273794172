<nav class="container ml-6 mt-2">
  <ol
    class="list-reset text-indigo-800 py-4 pl-4 rounded flex bg-grey-light text-grey text-sm font-semibold"
  >
    <li class="px-2">
      <a href="#" class="no-underline">Home</a>
    </li>
    <li>/</li>
    <li class="px-2">
      <a href="#" class="no-underline">Plans</a>
    </li>
  </ol>
</nav>

<div class="flex justify-center">
  <div class="flex flex-col items-center mt-10 px-4">
    <div *ngIf="plans.length; else plan_loader">
      <div class="flex flex-wrap justify-center gap-6">
        <div
          *ngFor="let plan of plans"
          class="relative w-full sm:w-[300px] md:w-[250px] lg:w-[300px] bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
        >
          <div
            [ngClass]="{
              'border-green-600': plan.isCurrentPlan,
              'border-blue-600': plan.isRecommended
            }"
            class="border-t-4 border-solid"
          ></div>
          <div class="p-6">
            <h4
              class="font-medium leading-tight text-xl md:text-2xl mb-2 text-center"
            >
              {{ plan.name }}
            </h4>
            <p class="text-gray-600 text-center">{{ plan.shortDesc }}</p>
          </div>
          <div
            [ngClass]="{ 'bg-green-300': plan.isCurrentPlan }"
            class="pricing-amount bg-indigo-100 p-6 text-center transition-colors duration-300"
          >
            <span class="text-3xl md:text-4xl font-semibold">{{
              plan.price
            }}</span>
          </div>
          <div class="p-6">
            <ul class="leading-loose text-center">
              <li *ngFor="let feat of plan.features">{{ feat }}</li>
            </ul>
            <div class="mt-6 py-4 text-center" *ngIf="!plan.isCurrentPlan">
              <button
                (click)="changePlan(plan)"
                [disabled]="loader"
                [ngClass]="{ 'bg-blue-100': loader }"
                class="bg-blue-500 text-lg md:text-xl text-white py-2 px-6 rounded hover:bg-blue-700 transition-colors duration-300"
              >
                Change Plan
              </button>
            </div>
            <div class="mt-6 py-4 text-center" *ngIf="plan.isCurrentPlan">
              <button
                [disabled]="loader"
                class="bg-green-500 text-lg md:text-xl text-white py-2 px-6 w-full rounded"
              >
                Current Plan
              </button>
            </div>
          </div>
          <div
            *ngIf="plan.isRecommended"
            class="absolute top-0 right-0 bg-yellow-400 text-white text-sm font-bold px-2 py-1 rounded-bl-lg"
          >
            Recommended
          </div>
        </div>
      </div>
    </div>

    <ng-template #plan_loader>
      <div class="w-full mt-6 flex justify-center">Plans Loading...</div>
    </ng-template>
  </div>

  <ng-template #plan_loader>
    <div class="w-full mt-6 flex flex-row justify-center">Plans Loading...</div>
  </ng-template>
</div>
