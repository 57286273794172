// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  salesforceRedirect: `salesforce.com/services/oauth2/authorize?client_id=3MVG9HB6vm3GZZR9SqTjqp3.YYSbTn9Jggj4RNP_h45P3QW4H5TR9LVgmtT58ln7SJYQzX7pSi76PZpgv84Fh&redirect_uri=https://dev.themethodic.com/onboard&response_type=code`,
  clientId:
    '3MVG9HB6vm3GZZR9SqTjqp3.YYSbTn9Jggj4RNP_h45P3QW4H5TR9LVgmtT58ln7SJYQzX7pSi76PZpgv84Fh',
  apiUrl:
    'https://162vpmvtil.execute-api.us-east-1.amazonaws.com/dev/middleware/',
  adminUrl: 'https://162vpmvtil.execute-api.us-east-1.amazonaws.com/dev/admin/',
  xAPIKey: 'cXGmH7XXB73OwBVvBs86664ic3VLMqqO1MM1yMqR',
  stripeTest: 'https://billing.stripe.com/p/login/test_dR63fZ0ru20EbNm8wx',
  stripeProd: 'https://billing.stripe.com/p/login/3csg0V2xWbJzc2kdQQ',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
