<div class="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
  <div
    class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"
  >
    <div
      class="p-4 py-6 text-white bg-[#007ce1] md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly"
    >
      <div class="my-3 text-4xl font-bold tracking-wider text-center">
        <img src="./../../../assets/images/themethodic-logo.png" alt="" />
      </div>
      <p class="mt-6 font-light text-sm text-center text-gray-300 md:mt-0">
        With the AI power of Methodic, you can now focus only on functionaries
        for your digital products, while leaving the template on us!
      </p>

      <p class="mt-6 text-sm text-center text-gray-300">
        Read our
        <a
          href="https://themethodic.com/terms-of-service"
          target="_blank"
          class="underline"
          >Terms
        </a>
        <br />
        &
        <br />
        <a
          href="https://themethodic.com/privacy-policy"
          target="_blank"
          class="underline"
          >Privacy Policy</a
        >
      </p>
    </div>
    <div class="p-5 bg-white md:flex-1">
      <h3
        class="my-4 text-2xl font-semibold text-gray-700"
        *ngIf="formType == 'LOGIN'"
      >
        Account Login
      </h3>
      <h3
        class="my-4 text-2xl font-semibold text-gray-700"
        *ngIf="formType == 'SIGNUP'"
      >
        Account Sign-Up
      </h3>
      <div
        class="p-2 bg-red-300 text-sm text-white rounded"
        *ngIf="!registerQueryparams && formType == 'SIGNUP'"
      >
        Organisation Environment and ID is required!
      </div>
      <div
        class="flex flex-col space-y-5"
        *ngIf="formType == 'LOGIN'"
        [formGroup]="signInForm"
      >
        <div class="flex flex-col space-y-1">
          <label for="email" class="text-sm font-semibold text-gray-500"
            >Email address</label
          >
          <input
            type="email"
            formControlName="email"
            id="email"
            autofocus
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
          />
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center justify-between">
            <label for="password" class="text-sm font-semibold text-gray-500"
              >Password</label
            >
            <a
              href="#"
              class="text-sm text-blue-600 hover:underline focus:text-blue-800"
              >Forgot Password?</a
            >
          </div>
          <input
            formControlName="password"
            type="password"
            id="password"
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent"
          />
        </div>

        <div>
          <button
            type="submit"
            (click)="login()"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#007ce1] rounded-md shadow hover:bg-blue-500"
          >
            <span class="loader" *ngIf="loader"></span>
            <span *ngIf="!loader">Sign In</span>
          </button>
        </div>
        <div class="flex flex-col space-y-5">
          <div class="flex flex-col space-y-4">
            <p
              class="flex flex-col items-center justify-center text-center cursor-pointer"
            >
              <span>Don't have an account?</span>
              <a (click)="changeFormType('SIGNUP')" class="underline"
                >Sign Up!</a
              >
            </p>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col space-y-5"
        *ngIf="formType == 'SIGNUP'"
        [formGroup]="signUpForm"
      >
        <div class="flex flex-col space-y-1">
          <label for="email" class="text-sm font-semibold text-gray-500"
            >Organisation Name</label
          >
          <input
            type="text"
            formControlName="orgname"
            [readOnly]="true"
            id="org"
            autofocus
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
          />
          <div
            *ngIf="
              signUpForm.get('orgname').invalid &&
              signUpForm.get('orgname').touched
            "
            class="error font-normal text-xs text-red-500"
          >
            Organization Name is required.
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <label for="email" class="text-sm font-semibold text-gray-500"
            >Email address</label
          >
          <input
            type="email"
            [readOnly]="true"
            formControlName="email"
            id="email"
            autofocus
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
          />
          <div
            *ngIf="
              signUpForm.get('email').invalid && signUpForm.get('email').touched
            "
            class="error font-normal text-xs text-red-500"
          >
            Please enter a valid email address.
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center justify-between">
            <label for="password" class="text-sm font-semibold text-gray-500">
              {{ ifEmailExist ? "Existing Password" : "Password" }}</label
            >
          </div>
          <input
            formControlName="password"
            [placeholder]="ifEmailExist ? 'Existing Password' : 'Password'"
            type="password"
            id="password"
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
          />
          <div
            *ngIf="
              signUpForm.get('password').invalid &&
              signUpForm.get('password').touched
            "
            class="error font-normal text-xs text-red-500"
          >
            Password must be at least 6 characters long.
          </div>
        </div>
        <div class="flex flex-col space-y-1" *ngIf="!ifEmailExist">
          <div class="flex items-center justify-between">
            <label for="password" class="text-sm font-semibold text-gray-500"
              >Confirm Password</label
            >
          </div>
          <input
            formControlName="confirmPassword"
            type="password"
            id="Confirm-Password"
            class="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
          />
          <div
            *ngIf="
              signUpForm.hasError('mismatch') &&
              signUpForm.get('confirmPassword').touched
            "
            class="error font-normal text-xs text-red-500"
          >
            Passwords do not match.
          </div>
        </div>

        <div>
          <button
            *ngIf="!ifEmailExist"
            (click)="onSubmit()"
            [disabled]="signUpForm.invalid"
            [ngClass]="{ 'bg-blue-200 hover:bg-blue-100': signUpForm.invalid }"
            type="submit"
            class="w-full px-4 py-2 text-md text-white transition-colors duration-300 bg-[#007ce1] rounded-md shadow hover: hover:bg-blue-500"
          >
            <span class="loader" *ngIf="loader"></span>
            <span *ngIf="!loader">Sign Up</span>
          </button>
        </div>
        <div>
          <button
            *ngIf="ifEmailExist"
            (click)="onSubmit()"
            [disabled]="signUpForm.invalid"
            [ngClass]="{ 'bg-blue-200 hover:bg-blue-100': signUpForm.invalid }"
            type="submit"
            class="w-full px-4 py-2 text-md text-white transition-colors duration-300 bg-[#007ce1] rounded-md shadow hover: hover:bg-blue-500"
          >
            <span class="loader" *ngIf="loader"></span>
            <span *ngIf="!loader">Continue with new environment setup</span>
          </button>
        </div>
        <div class="flex flex-col space-y-5">
          <div class="flex flex-col space-y-4">
            <p
              class="flex flex-col items-center justify-center text-center cursor-pointer"
            >
              <span>Have an account?</span>
              <a (click)="changeFormType('LOGIN')" class="underline"
                >Sign In!</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
