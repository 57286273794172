import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-plan-details',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.css'],
})
export class PlanDetailsComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PlanDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
  navigateTo(route) {
    this.router.navigate([route]);
  }
}
