import { Routes } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { OnboardComponent } from './onboard/onboard.component';
import { AuthGuard } from './_helpers/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PlansComponent } from './pages/plans/plans.component';
import { ConfigComponent } from './pages/config/config.component';
import { UsersComponent } from './pages/users/users.component';
import { BillingComponent } from './pages/billing/billing.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';

export const routes: Routes = [
  { path: '', component: AccountComponent },
  { path: 'onboard', component: OnboardComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },

  { path: 'plans', component: PlansComponent, canActivate: [AuthGuard] },
  { path: 'config', component: ConfigComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'billing', component: BillingComponent, canActivate: [AuthGuard] },
  // {
];
