import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AccountService } from './../../_services';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  private subscription: Subscription;
  public currentUser: any = {};
  constructor(private accountService: AccountService) {
    let isUserLogged = JSON.parse(localStorage.getItem('userInfo'));
    console.log(isUserLogged);
    this.currentUser = isUserLogged?.userInfo;
    // this.accountService.userValue.subscribe((data: any) => {});
  }

  get userName(): string {
    return this.currentUser?.email.split('@')[0].toUpperCase();
  }

  get avatarLetter(): string {
    return this.userName?.charAt(0);
  }

  ngOnInit(): void {
    this.subscription = this.accountService.user.subscribe((user) => {
      console.log('User updated:', user);
    });
  }
}
