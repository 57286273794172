import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-config',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './config.component.html',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ConfigComponent {
  registerInfo: any = null;
  configList: any = null;
  loader: boolean = false;
  callbackCode: string = '';
  bills = [
    { environment: 1, name: '2024-04-01', secretKey: 120.5 },
    // Add more dummy data as needed
  ];
  selectedEnv: string;
  isPopupVisible: boolean = false;
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.loader = true;
    this.form = this.fb.group({
      environment: ['', Validators.required],
      environmentName: ['', Validators.required],
    });
    this.getConfigList();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      let checkTriggerType = localStorage.getItem('trigger');
      this.callbackCode = params['code'];
      if (params['code'] && checkTriggerType == 'USER_CHANGE') {
        this.changeConnectedUser(params['code']);
      } else if (params['code'] && checkTriggerType == 'NEW_CONNECTION') {
        let selectedEnvionment = localStorage.getItem('new-connection');
        let selectedEnvionmentName = localStorage.getItem('connection-name');
        this.generateConfig(
          selectedEnvionment,
          selectedEnvionmentName,
          this.callbackCode
        );
      }
    });

    this.form
      .get('environmentName')
      .valueChanges.pipe(
        debounceTime(300), // Add a delay to prevent too many API calls
        distinctUntilChanged(), // Only call the API if the value has changed
        switchMap((value) => value && this.checkEnvironmentName(value))
      )
      .subscribe((result) => {
        if (result) {
          this.form.get('environmentName').setErrors(null);
        } else {
          this.form
            .get('environmentName')
            .setErrors({ environmentNameInvalid: true });
        }
      });
  }

  getConfigList() {
    this.backendApi.getMethod(`account-config`).subscribe({
      next: (resp: any) => {
        this.loader = false;
        resp.apikeys.map(
          (obj) => (obj.created = new Date(obj.created).toDateString())
        );
        this.configList = resp;
      },
      error: (error: any) => {},
    });
  }

  addNewConnection() {
    localStorage.setItem('new-connection', this.form.value.environment);
    localStorage.setItem('connection-name', this.form.value.environmentName);
    localStorage.setItem('trigger', 'NEW_CONNECTION'); //CALL FOR NEW CONNECTION TO SALESFORCE
    if (this.form.value.environment == 'production') {
      window.location.href = `https://login.${environment.salesforceRedirect}`;
    } else {
      window.location.href = `https://test.${environment.salesforceRedirect}`;
    }
  }

  openPopUp() {
    this.isPopupVisible = true;
  }
  closePopup() {
    this.isPopupVisible = false;
  }

  secretKey: string = '';
  isText: boolean = false;

  toggleShow() {
    this.isText = !this.isText;
  }

  generateConfig(environment, environmentName, callbackCode) {
    let payload = {
      authCode: callbackCode,
      environment,
      environmentName,
    };
    this.backendApi.postMethod('new-config', payload).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.getConfigList();
        this.toastr.success(response.message, 'Success');
        localStorage.removeItem('triggered-account');
        localStorage.removeItem('trigger');
        this.router.navigate(['/config']);
      },
      error: (error) => {
        this.loader = false;
        this.toastr.error('Failed', 'Error');
      },
    });
  }

  enableDisableEnv(orgId, status) {
    this.backendApi
      .patchMethod(`updateStatus?orgId=${orgId}&status=${status}`, {})
      .subscribe({
        next: (response: any) => {
          this.loader = false;
          this.getConfigList();
          this.toastr.success(response.message, 'Success');
        },
        error: (error) => {
          this.loader = false;
          this.toastr.error('Failed', 'Error');
        },
      });
  }

  repatchAPIKey(orgId) {
    this.backendApi.patchMethod(`patchKey?orgId=${orgId}`, {}).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.getConfigList();
        this.toastr.success(response.message, 'Success');
      },
      error: (error) => {
        this.loader = false;
        this.toastr.error('Failed', 'Error');
      },
    });
  }

  checkEnvironmentName(environmentName: string): Observable<boolean> {
    const url = `checkEnvironmentName?environmentName=${environmentName}`;
    return this.backendApi.getMethod(url).pipe(
      map((resp: any) => {
        // this.loader = false; // Set loader to false after getting the response
        return !resp.isEnvironmentName;
      }),
      catchError(() => {
        // this.loader = false; // Set loader to false in case of error
        return of(false);
      })
    );
  }

  changeUser(user) {
    localStorage.setItem('trigger', 'USER_CHANGE'); //TRIGGER WHEN CHANGE USER IS CALLED
    localStorage.setItem('triggered-account', JSON.stringify(user));
    if (user.environment == 'production') {
      window.location.href = `https://login.${environment.salesforceRedirect}`;
    } else {
      window.location.href = `https://test.${environment.salesforceRedirect}`;
    }
  }

  changeConnectedUser(authCode) {
    let accountInfo = JSON.parse(localStorage.getItem('triggered-account'));
    let payload = {
      authCode,
      environment: accountInfo.environment,
      orgId: accountInfo.orgId,
    };
    this.backendApi.postMethod('changeConnectedUser', payload).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.getConfigList();
        this.toastr.success(response.message, 'Success');
        localStorage.removeItem('triggered-account');
        localStorage.removeItem('trigger');
        this.router.navigate(['/config']);
      },
      error: (error) => {
        this.loader = false;
        this.toastr.error('Failed', 'Error');
      },
    });
  }
}
