import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
  bills = [
    { id: 1, date: '2024-04-01', amount: 120.5, status: 'Paid' },
    { id: 2, date: '2024-04-12', amount: 89.99, status: 'Unpaid' },
    { id: 3, date: '2024-04-15', amount: 300.0, status: 'Paid' },
    // Add more dummy data as needed
  ];
  plans: any = [];
  loader: boolean = false;
  registerInfo: any = {};
  adminList: any = {};
  filterForm: FormGroup;
  isPopupVisible: boolean = false;
  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.inviteForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      username: [''],
    });
  }

  ngOnInit() {
    this.getAdminList();
  }

  inviteForm: FormGroup;

  getAdminList() {
    this.backendApi.getMethod(`adminList?organizationName=revature`).subscribe({
      next: (resp: any) => {
        this.adminList = resp.adminList;
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  inviteUser() {
    if (!this.inviteForm.valid) {
      console.log('User invited:', this.inviteForm.value);
      return;
    }
    this.backendApi
      .getMethod(
        `inviteUser?email=${this.inviteForm.value.email}&username=${this.inviteForm.value.username}`
      )
      .subscribe({
        next: (resp: any) => {
          this.loader = false;
          this.toastr.success('Invite Sent Successful', 'Success');
          this.getAdminList();
          this.isPopupVisible = false;
        },
        error: (error: any) => {
          this.isPopupVisible = false;
          console.log(error);
          // this.toastr.error('Somthing Went Wrong', 'Error');
        },
      });
  }

  userName(email): string {
    return email.split('@')[0].toUpperCase();
  }

  openPopUp() {
    this.isPopupVisible = true;
  }
  closePopup() {
    this.isPopupVisible = false;
  }

  enableDisableUser(admin) {
    console.log(admin);
    let toBeChanged = admin.status == 'enabled' ? 'disabled' : 'enabled';
    this.backendApi
      .putMethod(
        `updateUserStatus?email=${admin.email}&status=${toBeChanged}`,
        {}
      )
      .subscribe({
        next: (resp: any) => {
          this.adminList = resp.adminList;
          this.getAdminList();
          this.toastr.success('Status Changed', 'Success');
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }
}
