import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reset-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css'],
})
export class ResetPasswordDialogComponent implements OnInit {
  resetPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('newPassword').value === form.get('confirmPassword').value
      ? null
      : { mismatch: true };
  }
  loading: boolean = false;
  resetPassword() {
    if (this.resetPasswordForm.valid) {
      let url = `resetPassword?challengeName=NEW_PASSWORD_REQUIRED&email=${this.data.email}&userId=${this.data.resetInfo.userId}&session=${this.data.resetInfo.session}&newPassword=${this.resetPasswordForm.value.newPassword}`;
      this.loading = true;
      this.accountService.putMethod(url, {}).subscribe({
        next: (response: any) => {
          this.dialogRef.close();
          this.loading = false;
          this.toastr.success('Password reset successful', 'Success');
          localStorage.setItem('userInfo', JSON.stringify(response));
          this.router.navigate(['/dashboard']);

          this.loading = false;
        },
        error: (error) => {
          this.toastr.error(error, 'Error');
        },
      });
    } else {
      this.toastr.error('Form is Invalid', 'Error');
    }
  }

  close() {
    this.dialogRef.close();
  }
}
