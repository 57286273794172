import { Component } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { AccountService, BackendApiService } from './_services';
import { environment } from './../environments/environment';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderComponent,
    CommonModule,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  showHeader: boolean = true;
  billEnv: string;
  menuItems = [
    { name: 'Dashboard', link: '/dashboard', icon: 'fa-solid fa-tv' },
    { name: 'Plan', link: '/plans', icon: 'fa-regular fa-newspaper' },
    {
      name: 'Connections',
      link: '/config',
      icon: 'fa-regular fa-rectangle-list',
    },
    { name: 'Users', link: '/users', icon: 'fa-solid fa-user' },
    { name: 'Billing', link: '/billing', icon: 'fa-regular fa-credit-card' },
  ];
  userInfo: any = null;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private backendApi: BackendApiService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    // const user = this.accountService.userValue || null;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.indexOf('/onboard') != -1 ||
          event.url == '/' ||
          event.url.indexOf('/?orgId') != -1
        ) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
          this.setActiveMenuItem(event.urlAfterRedirects);
        }
      }
    });
    this.setActiveMenuItem(this.router.url);
  }
  selectedMenu: any = 'Dashboard';
  navigateTo(path) {
    this.selectedMenu = path.name;
    this.router.navigate([path.link]);
  }

  setActiveMenuItem(url: string) {
    const foundMenuItem = this.menuItems.find((menu) =>
      url.includes(menu.link)
    );
    if (foundMenuItem) {
      this.selectedMenu = foundMenuItem.name;
    }
  }

  customerPortal() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // this.billEnv =
    //   environment.stripeTest +
    //   '?prefilled_email=' +
    //   this.userInfo.userInfo.user_name;

    // window.open(this.billEnv, '_blank');

    this.backendApi.getMethod(`billing`).subscribe({
      next: (resp: any) => {
        console.log(resp);
        window.open(resp.url, '_blank');
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }
}
