<header>
  <nav
    class="flex flex-wrap items-center justify-between w-full py-3 md:py-0 px-4 text-lg text-white bg-blue-800 shadow-lg"
  >
    <div>
      <img
        src="./../../../assets/images/themethodic-logo.png"
        alt=""
        class="w-[40%]"
      />
    </div>

    <div class="hidden w-full md:flex md:items-center md:w-auto py-1" id="menu">
      <div
        class="cursor-pointer mr-2 w-8 h-8 relative flex justify-center items-center rounded-full bg-blue-500 text-xl text-white uppercase"
      >
        <span>{{ avatarLetter }}</span>
      </div>
      <div class="text-sm">{{ userName }}</div>
    </div>
  </nav>
</header>
