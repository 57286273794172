import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, BackendApiService } from './../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-onboard',
  standalone: true,
  providers: [AccountService],
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: `./onboard.component.html`,
})
export class OnboardComponent {
  bannerOpen: boolean = false;
  screenType = 'PLAN';
  plans: any = [];
  loader: boolean = false;
  registerInfo: any = {};
  callbackCode: string = null;
  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('user'));
    let isUserLogged = JSON.parse(localStorage.getItem('userInfo'));
    if (!isUserLogged) {
      if (!this.registerInfo) {
        this.toastr.error('Invalid Route', 'Error');
        this.router.navigate(['/']);
      }
    }
  }
  selectedPlan: any = {};
  paramsSubscription: any;
  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParams.subscribe((params) => {
      this.callbackCode = params['code'];
      let checkIfUserLogged = JSON.parse(localStorage.getItem('userInfo'));
      this.registerInfo = JSON.parse(localStorage.getItem('user'));
      ///['CODE'] COMES FROM SALESFORCE
      if (checkIfUserLogged && params['code']) {
        //THIS IS THE CASE WHEN USER IS LOGGED IN AND TRYING TO CREATE A NEW SALESFORCE CONNECTION
        this.router.navigate(['/config'], {
          queryParams: { code: params['code'] },
        });
        return;
      }
      if (params['code']) {
        this.screenType = 'LOADER';
        this.loader = true;
        this.generateConfig();
      }
      if (params['environment']) {
        //ENVIRONMENT WILL COME FROM
        this.registerInfo['environment'] = params['environment'];
        localStorage.setItem('salesforce_env', params['environment']);
      }
      if (params['isSubscription']) {
        //THIS WILL COME FROM LOGIN IF USER IS ALREADY HAD SUBSCRIPTION
        this.screenType = 'ESTABLISH_CONNECTION';
        this.toastr.success('Your Have a Pre Selected Plan', 'Success');
      }
      if (
        params['status'] &&
        params['account_id'] &&
        this.registerInfo.accountId
      ) {
        console.log('Checking Verifying');
        //  THIS IS WHEN USER COME FROM STRIVE PAYMENT VERIFICATION DONE.
        if (this.registerInfo.accountId == params['account_id']) {
          this.verifyPayment(params['account_id'], this.registerInfo.userId);
        } else {
          this.toastr.error('Payment failed', 'Error');
        }
      }
    });

    this.getPlans();
  }

  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
  }

  verifyPayment(accountId, userId) {
    this.backendApi
      .getMethod(`account-subscription?accountId=${accountId}&userId=${userId}`)
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.subscription) {
            this.screenType = 'ESTABLISH_CONNECTION';
            this.toastr.success('Payment Success', 'Success');
          } else {
            this.toastr.error('Payment failed, Please try agin', 'Error');
          }
        },
        error: (error) => {
          this.toastr.error('Payment failed', 'Error');
        },
      });
  }

  selectPlan(plan) {
    this.loader = true;
    let payload = {
      accountId: this.registerInfo?.accountId,
      userId: this.registerInfo?.userId,
      skuId: plan._id,
    };
    this.selectedPlan = plan;
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    this.backendApi.postMethod('account-subscription', payload).subscribe({
      next: (response: any) => {
        this.loader = false;
        localStorage.setItem('subscription', JSON.stringify(response));
        if (plan.name.toLowerCase() == 'free') {
          this.screenType = 'ESTABLISH_CONNECTION';
          this.toastr.success('Subscription added successful', 'Success');
        } else {
          window.location.href = response.checkoutSessionUrl;
        }
      },
      error: (error) => {
        this.loader = false;
        this.toastr.error('Subscription failed', 'Error');
      },
    });
  }

  reviewPlan: boolean = false;
  goBackTOCheckYourPlan() {
    console.log(this.selectedPlan);
    let selectedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
    if (selectedPlan) {
      this.screenType = 'PLAN';
      this.plans = [];
      this.plans = [selectedPlan];
      this.reviewPlan = true;
      this.backendApi.launchConfetti();
    } else {
      this.toastr.error('Somthing went wrong, contact Admin', 'Error');
    }
  }

  getPlans() {
    this.backendApi.getMethod('account-subscription').subscribe({
      next: (resp: any) => {
        this.plans = resp;
      },
      error: (error: any) => {},
    });
  }

  apiKey: string = '';
  countdown: number = 3;
  generateConfig() {
    let payload = {
      accountId: this.registerInfo?.accountId,
      userId: this.registerInfo?.userId,
      authCode: this.callbackCode,
    };
    this.backendApi.postMethod('account-config', payload).subscribe({
      next: (response: any) => {
        this.loader = false;
        this.screenType = 'API_KEY_SCREEN';
        localStorage.setItem('apiKey', JSON.stringify(response));
        this.backendApi.launchConfetti();
        this.apiKey = response.configId;
        this.loginToDashboard();
        this.toastr.success('Subscription successful', 'Success');
      },
      error: (error) => {
        this.loader = false;
        this.toastr.error('Subscription failed', 'Error');
      },
    });
  }

  loginToDashboard() {
    this.backendApi.getMethod(`dashboard`).subscribe({
      next: (resp: any) => {
        let getInfoFromRegister = JSON.parse(localStorage.getItem('user'));
        resp.token = getInfoFromRegister.token;
        localStorage.setItem('userInfo', JSON.stringify(resp));
        localStorage.removeItem('user');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('subscription');
        localStorage.removeItem('selectedPlan');
        localStorage.removeItem('salesforce_env');
        localStorage.removeItem('connection-name');
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            this.router.navigate(['/dashboard']);
            clearInterval(interval);
          }
        }, 1500);
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  createConnection() {
    if (localStorage.getItem('salesforce_env') == 'production') {
      window.location.href = `https://login.${environment.salesforceRedirect}`;
    } else {
      window.location.href = `https://test.${environment.salesforceRedirect}`;
    }
  }
  nagHome() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  copyToClipboard() {
    var inputField = document.getElementById('apiKeyInput');
    if (inputField instanceof HTMLInputElement) {
      inputField.select();
      document.execCommand('copy');
      this.toastr.success('Copied to clipboard', 'Success');
    } else {
      console.error('Unable to access input field.');
    }
  }
}
