<div
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
>
  <div class="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
    <h1 class="text-xl font-semibold mb-4">Warning</h1>
    <div class="mb-4">
      <p>Are you sure you want to change the plan?</p>
      <p>Please type "Confirm" to proceed.</p>
      <mat-form-field class="w-full mt-2">
        <input
          matInput
          [(ngModel)]="consentText"
          placeholder="Confirm"
          class="w-full border p-2 rounded"
        />
      </mat-form-field>
    </div>
    <div class="mb-4">
      <h2 class="text-lg font-medium">Consequences of Changing Plans:</h2>
      <ul class="list-disc pl-5 mt-2">
        <li>You may lose access to certain features.</li>
        <li>Billing cycle may be affected.</li>
        <li>Data limitations might apply.</li>
        <li>
          Previous plan benefits might not be restored if you switch back.
        </li>
      </ul>
    </div>
    <div class="flex justify-end space-x-2">
      <button
        mat-button
        (click)="onCancel()"
        class="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
      >
        Cancel
      </button>
      <button
        mat-button
        [disabled]="consentText !== 'Confirm'"
        (click)="onConfirm()"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Continue
      </button>
    </div>
  </div>
</div>
