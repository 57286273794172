import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AccountService, BackendApiService } from './../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ConfirmDialogComponent],
  templateUrl: './plans.component.html',
})
export class PlansComponent {
  plans: any = [];
  loader: boolean = false;
  registerInfo: any = {};
  orgInfo: any = {};
  filterForm: FormGroup;
  tokenInfo: any = {};
  planInfo: any = {};

  constructor(
    private fb: FormBuilder,
    private backendApi: BackendApiService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.registerInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.filterForm = this.fb.group({
      environment: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['status']) {
        if (params['status'] == 'success') {
          this.toastr.success('Payment Success', 'Success');
          this.backendApi.launchConfetti();
          this.router.navigate(['/plans']);
        } else {
          this.toastr.error('Payment failed', 'Error');
        }
      }
    });

    this.backendApi.getMethod(`dashboard`).subscribe({
      next: (resp: any) => {
        resp.dashboard.usagebyEnvAndOrgId =
          resp.dashboard.usagebyEnvAndOrgId.map((item) => {
            const environment =
              item.environment.charAt(0).toUpperCase() +
              item.environment.slice(1);
            const tokenlimit = parseInt(item.tokenlimit);
            const tokenUsed = parseInt(item.tokenUsed);
            const percentage = (tokenUsed / tokenlimit) * 100;
            return {
              environment: environment,
              orgId: item.orgId,
              tokenlimit: item.tokenlimit,
              tokenUsed: item.tokenUsed,
              percentage: percentage.toFixed(2) + '%',
            };
          });
        this.orgInfo = resp;
        this.filterForm.patchValue({
          environment: this.orgInfo.filter[0].orgId,
        });
        this.onChange();
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  onChange() {
    this.backendApi
      .getMethod(
        `account-subscription?orgId=${this.filterForm.value.environment}`
      )
      .subscribe({
        next: (resp: any) => {
          console.log(resp);
          this.plans = resp.plan;
          this.planInfo = resp.plan.filter((obj) => obj.isCurrentPlan)[0];
          this.tokenInfo = this.orgInfo.dashboard.usagebyEnvAndOrgId.filter(
            (obj) => obj.orgId == this.filterForm.value.environment
          )[0];
          console.log(this.planInfo, this.tokenInfo);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }

  changePlan(plan) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: { plan },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // proceed with changing the plan
        console.log('Plan changed to:', plan.name);
        this.loader = true;
        let payload = {
          skuId: plan._id,
        };
        this.backendApi.postMethod('changePlan', payload).subscribe({
          next: (response: any) => {
            this.loader = false;
            console.log(response);
            if (response.checkoutSessionUrl) {
              window.location.href = response.checkoutSessionUrl;
            } else {
              this.toastr.success('Plan Chaned Successfully', 'Success');
              this.backendApi.launchConfetti();
              this.onChange();
            }
          },
          error: (error) => {
            this.loader = false;
            this.toastr.error('Subscription failed', 'Error');
          },
        });
      } else {
        console.log('Plan change cancelled');
      }
    });
  }
}
