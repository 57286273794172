<app-header *ngIf="showHeader" class="shadow-md"> </app-header>
<div class="flex flex-row w-full" *ngIf="showHeader">
  <div class="shadow-lg h-[calc(100vh-40px)] w-[200px] text-white bg-[#007ce1]">
    <div *ngFor="let menu of menuItems" class="p-4">
      <div
        *ngIf="menu.name !== 'Billing'"
        (click)="navigateTo(menu)"
        [ngClass]="{ 'bg-blue-800': menu.name == selectedMenu }"
        class="text-white flex justify-between px-4 py-2 rounded text-md font-light hover:bg-blue-800 cursor-pointer"
      >
        <span> <i [ngClass]="menu.icon"></i> {{ menu.name }}</span>
        <i
          class="fa-solid fa-arrow-right mt-1"
          *ngIf="menu.name == selectedMenu"
        ></i>
      </div>
      <a
        (click)="customerPortal()"
        target="_blank"
        *ngIf="menu.name == 'Billing'"
        [ngClass]="{ 'bg-blue-800': menu.name == selectedMenu }"
        class="text-white flex justify-between px-4 py-2 rounded text-md font-light hover:bg-blue-800 cursor-pointer"
      >
        <span> <i [ngClass]="menu.icon"></i> {{ menu.name }}</span>
        <i
          class="fa-solid fa-arrow-right mt-1"
          *ngIf="menu.name == selectedMenu"
        ></i>
      </a>
    </div>
    <div class="absolute bottom-0 p-4">
      <div
        (click)="logout()"
        class="md:p-4 py-2 block text-md font-light text-white cursor-pointer"
      >
        <i class="fa-solid fa-right-from-bracket"></i> Logout
      </div>
    </div>
  </div>
  <div class="w-[85%]">
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="!showHeader"></router-outlet>
