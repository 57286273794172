import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AccountService } from './../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isUserLogged = JSON.parse(localStorage.getItem('userInfo'));
    if (isUserLogged) {
      // authorised so return true
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
