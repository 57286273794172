<div class="bg-white rounded-lg shadow-lg overflow-hidden">
  <div
    class="flex justify-between items-center px-6 py-4 border-b border-gray-200"
  >
    <h2 class="text-xl font-bold text-gray-800">Reset Password</h2>
    <button
      type="button"
      class="text-gray-400 hover:text-gray-600"
      (click)="close()"
    >
      <svg
        class="h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  </div>
  <div class="px-6 py-4">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="mb-4">
        <label for="newPassword" class="block text-sm font-medium text-gray-700"
          >New Password</label
        >
        <input
          type="password"
          id="newPassword"
          formControlName="newPassword"
          class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
        <div
          *ngIf="
            resetPasswordForm.get('newPassword').touched &&
            resetPasswordForm.get('newPassword').invalid
          "
          class="text-red-500 text-sm mt-1"
        >
          <div *ngIf="resetPasswordForm.get('newPassword').errors['required']">
            New password is required.
          </div>
          <div *ngIf="resetPasswordForm.get('newPassword').errors['minlength']">
            New password must be at least 6 characters.
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label
          for="confirmPassword"
          class="block text-sm font-medium text-gray-700"
          >Confirm Password</label
        >
        <input
          type="password"
          id="confirmPassword"
          formControlName="confirmPassword"
          class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
        <div
          *ngIf="
            resetPasswordForm.get('confirmPassword').touched &&
            resetPasswordForm.get('confirmPassword').invalid
          "
          class="text-red-500 text-sm mt-1"
        >
          <div
            *ngIf="resetPasswordForm.get('confirmPassword').errors['required']"
          >
            Confirm password is required.
          </div>
          <div
            *ngIf="resetPasswordForm.get('confirmPassword').errors['minlength']"
          >
            Confirm password must be at least 6 characters.
          </div>
          <div *ngIf="resetPasswordForm.errors?.['mismatch']">
            Passwords do not match.
          </div>
        </div>
      </div>
      <button
        type="submit"
        [disabled]="this.loading"
        class="w-full bg-blue-600 text-white font-semibold my-4 py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Reset Password
      </button>
    </form>
  </div>
</div>
