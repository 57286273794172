import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import confetti from 'canvas-confetti';
// const confetti = require('canvas-confetti');
@Injectable({
  providedIn: 'root',
})
export class BackendApiService {
  videoStreaming: any = '';
  getExtractedFaceFromID: any = '';
  userInfo: any = '';
  viewCredentials: any = {};
  GLOBALDATA: any = {};

  constructor(
    public http: HttpClient //  public authService: AuthenticationService,
  ) {}

  getMethod(endpoint: string): Observable<[]> {
    return this.http.get<any>(`${environment.apiUrl}${endpoint}`);
  }

  postMethod(endpoint: string, body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}${endpoint}`, body);
  }

  putMethod(endpoint: string, body: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}${endpoint}`, body);
  }

  patchMethod(endpoint: string, body: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${endpoint}`, body);
  }

  getAdminMethod(endpoint: string): Observable<[]> {
    return this.http.get<any>(`${environment.adminUrl}${endpoint}`);
  }

  postAdminMethod(endpoint: string): Observable<[]> {
    return this.http.get<any>(`${environment.apiUrl}${endpoint}`);
  }

  launchConfetti() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  }
}
