<div
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
>
  <div class="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-2xl font-semibold text-gray-800">{{ data.plan.name }}</h2>
      <button (click)="closeDialog()" class="text-gray-500 hover:text-gray-700">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <p class="text-gray-600 mb-6">
      {{ data.plan.description }}
      <!-- Placeholder for additional description -->
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel urna
      eget eros blandit varius nec ut est. Sed in purus quis ligula pretium
      vehicula.
    </p>
    <ul class="list-none mb-6 space-y-4">
      <li *ngFor="let feature of data.plan.features" class="flex items-center">
        <mat-icon class="mr-2 text-green-500">check_circle</mat-icon>
        <span>{{ feature }}</span>
      </li>
      <!-- Placeholder list items -->
      <li class="flex items-center">
        <mat-icon class="mr-2 text-green-500">check_circle</mat-icon>
        <span>Feature 1: Lorem ipsum dolor sit amet</span>
      </li>
      <li class="flex items-center">
        <mat-icon class="mr-2 text-green-500">check_circle</mat-icon>
        <span>Feature 2: Consectetur adipiscing elit</span>
      </li>
      <li class="flex items-center">
        <mat-icon class="mr-2 text-green-500">check_circle</mat-icon>
        <span>Feature 3: Proin vel urna eget eros blandit</span>
      </li>
    </ul>
    <div class="flex justify-end space-x-4">
      <button
        (click)="closeDialog()"
        class="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
      >
        Close
      </button>
      <!-- Placeholder button for additional actions -->
      <!-- <button
        (click)="navigateTo('/plans')"
        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Perform Action
      </button> -->
    </div>
  </div>
</div>
