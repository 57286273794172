<nav class="container ml-6 mt-2">
  <ol
    class="list-reset text-indigo-800 py-4 pl-4 rounded flex bg-grey-light text-grey text-sm font-semibold"
  >
    <li class="px-2">
      <a href="#" class="no-underline">Home</a>
    </li>
    <li>/</li>
    <li class="px-2">
      <a href="#" class="no-underline">Config</a>
    </li>
  </ol>
</nav>

<div class="container mx-auto mt-4 p-6" *ngIf="!loader">
  <div class="flex flex-col">
    <div class="flex flex-row justify-end">
      <button
        (click)="openPopUp()"
        class="bg-blue-600 text-sm text-white py-2 px-6 rounded hover:bg-blue-500 transition-colors duration-300"
      >
        Add New Connection
      </button>
    </div>
    <div class="my-2 overflow-y-auto sm:-mx-6 lg:-mx-8 max-h-[70vh]">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="flex flex-row flex-wrap">
          <div
            class="max-w-sm w-full bg-gradient-to-r m-5 from-blue-100 to-white text-gray-500 p-6 rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
            *ngFor="let bill of configList.apikeys"
            [@fadeIn]
          >
            <div class="card-header mb-4">
              <h3 class="text-lg font-bold uppercase">{{ bill.name }}</h3>
            </div>
            <div class="card-content mb-4">
              <p class="mt-2"><strong>OrgID:</strong> {{ bill.orgId }}</p>
              <p class="mt-2">
                <strong>Environment:</strong> {{ bill.environment }}
              </p>
              <p class="mt-2">
                <strong>Status:</strong>
                <span
                  class="inline-block px-2 rounded"
                  [ngClass]="{
                    'bg-green-200 text-green-800': bill.status,
                    'bg-red-200 text-red-800': !bill.status
                  }"
                >
                  {{ bill.status ? "Active" : "In-Active" }}
                </span>
              </p>
              <p class="mt-2">
                <strong>App Install:</strong>
                <span
                  class="inline-block px-2 rounded"
                  [ngClass]="{
                    'bg-green-200 text-green-800': bill.appInstall,
                    'bg-red-200 text-red-800': !bill.appInstall
                  }"
                >
                  {{ bill.appInstall ? "Installed" : "Not Installed" }}
                </span>
              </p>
              <p class="mt-2">
                <strong>Created On:</strong> {{ bill.created }}
              </p>
              <p class="mt-2">
                <strong>Salesforce Account:</strong>
                {{ bill.salesforceUserInfo.name }}
              </p>
              <p class="mt-2">
                <strong>Salesforce User ID:</strong>
                {{ bill.salesforceUserInfo.user_id }}
              </p>
            </div>
            <div class="card-actions flex space-x-2">
              <button
                (click)="repatchAPIKey(bill.orgId)"
                class="btn btn-primary bg-blue-600 hover:bg-blue-300 text-white py-2 px-4 rounded text-sm"
              >
                Repatch API Key
              </button>
              <button
                (click)="enableDisableEnv(bill.orgId, !bill.status)"
                class="btn btn-secondary bg-green-600 hover:bg-green-300 text-white py-2 px-4 rounded text-sm"
              >
                {{ bill.status ? "Disable" : "Enable" }} Environment
              </button>
              <button
                (click)="changeUser(bill)"
                class="btn btn-tertiary bg-yellow-600 hover:bg-yellow-400 text-white py-2 px-4 rounded text-sm"
              >
                Change User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mx-auto mt-4 p-6 text-center" *ngIf="loader">
  Loading...
</div>

<div
  *ngIf="isPopupVisible"
  class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75"
>
  <div class="bg-white rounded-lg shadow-lg p-8 max-w-sm w-full">
    <h2 class="text-xl font-semibold mb-4">Add New Connection</h2>
    <form [formGroup]="form" (ngSubmit)="addNewConnection()">
      <div class="mb-4">
        <label
          for="environment"
          class="block text-sm font-medium text-gray-700 mb-2"
          >Environment</label
        >
        <select
          formControlName="environment"
          name="environment"
          class="block w-full border py-2 px-2 mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="sandbox">Sandbox</option>
          <option value="production">Production/Dev</option>
        </select>
      </div>
      <div class="mb-4">
        <label
          for="environmentName"
          class="block text-sm font-medium text-gray-700 mb-2"
          >Environment Name</label
        >
        <input
          type="text"
          formControlName="environmentName"
          name="environmentName"
          class="block py-2 px-2 w-full border mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <div
          *ngIf="form.get('environmentName').hasError('environmentNameInvalid')"
          class="text-red-500 text-sm mt-1"
        >
          Environment name is invalid.
        </div>
        <div
          *ngIf="
            this.form.value.environmentName &&
            !form.get('environmentName').hasError('environmentNameInvalid')
          "
          class="text-green-500 text-sm mt-1"
        >
          Environment name is available.
        </div>
      </div>
      <div class="flex justify-end">
        <button
          type="button"
          (click)="closePopup()"
          class="mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          [disabled]="form.invalid"
          type="submit"
          class="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
