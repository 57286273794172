import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { AccountService } from './../../app/_services';

import { HttpInterceptorFn } from '@angular/common/http';

export const JwtInterceptor: HttpInterceptorFn = (req, next) => {
  let authReq = null;
  if (
    req.url.indexOf('signin') != -1 ||
    req.url.indexOf('signup') != -1 ||
    req.url.indexOf('isExist?email') != -1
  ) {
    authReq = req.clone({
      setHeaders: {
        'X-API-key': `${environment.xAPIKey}`,
      },
    });
    return next(authReq);
  } else if (JSON.parse(localStorage.getItem('userInfo'))) {
    const authToken = JSON.parse(localStorage.getItem('userInfo'))?.token;
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return next(authReq);
  } else if (JSON.parse(localStorage.getItem('user'))) {
    const authToken = JSON.parse(localStorage.getItem('user'))?.token;
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return next(authReq);
  } else {
    return next(req);
  }

  // Pass the cloned request with the updated header to the next handler
};
